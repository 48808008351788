import StatsigEvent from '../../common/StatsigEvent'
import { initializeStatsig } from '../../mixins/StatsigHelper'
import UserApi from '../../apis/UserApi'

export const namespaced = true

export const state = {
    vue: null,
    premium_marker_color: null,
    premium_fonts: 'basic',
    default_widget_dropdown: 'instruction',
    free_plan_layout: 'top-center-section',
    paywall_type: 'checkout',
    header_styles: false,
    worksheetPrintActionText: 'Finish + Print',
    flashcardPrintActionText: 'Make Printable',
    bingoPrintActionText: 'Make Printable',
    loadingMakePrintable: true,
    worksheetTitle: 'Log in to publish this worksheet',
    bingoTitle: 'Log in to publish these bingo cards',
    flashcardTitle: 'Log in to publish these flashcards',
    loading: true,
    blank_title: false,
    defaultTitle: '',
    abGroup: 0,
    show_answer_key: 0,
    fillInTheBlankInstructionText: "Write <b>full sentence</b>. Don't skip any words.",
    fillInTheBlankPlaceholderText: 'Write your complete sentence.',
    stickyHeader: false,
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },
    SET_AB_GROUP(state, group) {
        state.abGroup = group
    },
}

export const actions = {
    setValue({ commit }, value) {
        commit('SET_VALUE', value)
    },
    async initialize({ dispatch, rootGetters }, payload = {}) {
        try {
            state.loading = true
            await initializeStatsig()

            if (payload.completed) payload.completed()

            dispatch('logStatsigEvent', { event: StatsigEvent.USER_VISIT })

            const isAuthtenticatedUser = rootGetters['user/isLoggedIn']
            const userHasTrialed = rootGetters['user/hasTrialed']
            const userIsOnFreePlan = rootGetters['user/isOnFreePlan']

            if (
                window?.new_browser &&
                !isAuthtenticatedUser &&
                (!document.referrer ||
                    !document.referrer.includes('myworksheetmaker') ||
                    !document.referrer.includes('brightsprout'))
            ) {
                // TODO: deprecate this event.
                dispatch('logStatsigEvent', { event: StatsigEvent.NEW_USER_VISIT })
            } else if (isAuthtenticatedUser) {
                await dispatch('loadAbGroup')
            }

            dispatch('loadAbTests')
            dispatch('loadHeaderStylesAb')
            dispatch('loadBlankTitleTests')

            // Load experiments that are only for free plan users who have trialed (cardless).
            // TODO: Refactor this to use a more robust solution.
            if (isAuthtenticatedUser && userHasTrialed && userIsOnFreePlan) {
                await dispatch('user/getPayments', null, { root: true })
                await dispatch('cards/getCards', null, { root: true })

                const userHasMadePayments = rootGetters['user/getPayments']
                const userHasDefaultCard = !!rootGetters['cards/defaultCard']

                if (userHasMadePayments.length > 0 || userHasDefaultCard) {
                    return
                }

                dispatch('loadFreeTrialExpiredExperiments')
            }
        } catch (error) {
            console.error('Error initializing Statsig', error)
        } finally {
            state.loading = false
        }
    },
    logStatsigEvent({ rootGetters }, { event, value, payload }) {
        if (!window.browser_id) return
        if (!window.statsig?.instance) return
        if (!value) {
            value = rootGetters['document/entityType']
        }

        payload = {
            ...(payload || {}),
            page: rootGetters['document/entityType'],
            abGroup: state.abGroup,
            url: window.location.href,
            referrer: document.referrer,
            isNewBrowser: window.new_browser,
            isAuthenticatedUser: rootGetters['user/isLoggedIn'],
        }

        window.statsig?.logEvent(event, value, payload)
    },
    loadAbTests({ commit }) {
        commit('SET_VALUE', {
            premium_marker_color: '#715dd6',
            default_widget_dropdown: window.statsig
                ?.getExperiment('default_widget_dropdown')
                .get('default_widget', 'instruction'),
        })
    },
    loadFreeTrialExpiredExperiments({ commit }) {
        commit('SET_VALUE', {
            premium_fonts: window.statsig?.getExperiment('premium_fonts').get('fonts', 'basic'),
            paywall_type: window.statsig?.getExperiment('paywall_vs_checkout').get('paywall', 'checkout'),
        })
    },
    loadHeaderStylesAb({ commit }) {
        commit('SET_VALUE', {
            header_styles: window.statsig
                ?.getExperiment('header_styles_behind_a_feature_button')
                .get('feature_enabled', false),
        })
    },
    loadBlankTitleTests({ rootGetters, commit }) {
        let entityType = `${rootGetters['document/entityType']}`
        if (!entityType || entityType === 'worksheet') return

        let experiment = ''
        if (entityType === 'flashcard') {
            experiment = 'flashcards_default_title'
        } else if (entityType === 'bingo') {
            experiment = 'bingo_default_title'
        }

        commit('SET_VALUE', {
            blank_title: window.statsig?.getExperiment(`${experiment}`).get('blank_title', false),
        })
    },
    loadWorksheetsDefaultTitleTests({ commit, rootGetters }) {
        let entityType = `${rootGetters['document/entityType']}`

        if (entityType !== 'worksheet') return

        commit('SET_VALUE', {
            defaultTitle: window.statsig
                ?.getExperiment('worksheets_default_title_option')
                .get('title', 'My Worksheet Title'),
        })
    },
    loadFreePlanLayoutTest({ state, rootGetters }) {
        let entityType = `${rootGetters['document/entityType']}`
        if (!entityType) return

        // let freePlanLayout = !window.user?.trialed ? window.statsig?.getExperiment(`${entityType}_free_plan_layout`).get('layout', state.free_plan_layout) : state.free_plan_layout
        // TODO: Investigate why this is set like this. It doesn't do anything.
        let freePlanLayout = state.free_plan_layout
    },
    loadMakePrintableTests({ commit }) {
        switch (window.doc.entity_type) {
            case 'flashcard':
                commit('SET_VALUE', {
                    flashcardPrintActionText: window.statsig
                        ?.getExperiment('flashcards_make_printable_action_text')
                        .get('text', 'Make Printable'),
                    loadingMakePrintable: false,
                })
                break
            case 'bingo':
                commit('SET_VALUE', {
                    bingoPrintActionText: window.statsig
                        ?.getExperiment('bingo_make_printable_action_text')
                        .get('text', 'Make Printable'),
                    loadingMakePrintable: false,
                })
                break
            default:
                commit('SET_VALUE', {
                    loadingMakePrintable: false,
                })
                break
        }
    },
    loadDomainCreateAccountHeaderTests({ commit }) {
        switch (window.doc.entity_type) {
            case 'flashcard':
                commit('SET_VALUE', {
                    flashcardTitle: window.statsig
                        ?.getExperiment('flashcards_create_account_header_text')
                        .get('title', 'Log in to publish these flashcardscards'),
                })
                break
            case 'bingo':
                commit('SET_VALUE', {
                    bingoTitle: window.statsig
                        ?.getExperiment('bingo_create_account_header_text')
                        .get('title', 'Log in to publish these bingo cards'),
                })
                break
            case 'worksheet':
                commit('SET_VALUE', {
                    worksheetTitle: window.statsig
                        ?.getExperiment('worksheets_create_account_header_text')
                        .get('title', 'Log in to publish this worksheet'),
                })
                break

            default:
                break
        }
    },
    async loadAbGroup({ commit }) {
        try {
            const response = await UserApi.getAbGroup()
            commit('SET_AB_GROUP', response.group)
        } catch (error) {
            console.error('Error getting AB group', error)
        }
    },
    async loadShowAnswerKeyTest({ commit }) {
        switch (window.doc.entity_type) {
            case 'worksheet':
                commit('SET_VALUE', {
                    show_answer_key: await window.statsig?.getExperiment('show_answer_key_default').get('status', 0),
                })
                break
            default:
                break
        }
    },
    loadFillInTheBlankTests({ commit, rootGetters }) {
        if (!window.statsig) {
            return
        }

        const entityType = rootGetters['document/entityType']

        if (entityType !== 'worksheet') return

        commit('SET_VALUE', {
            fillInTheBlankInstructionText: window.statsig
                ?.getExperiment('fill_in_the_blank_instruction_text')
                .get('text', state.fillInTheBlankInstructionText),
        })

        commit('SET_VALUE', {
            fillInTheBlankPlaceholderText: window.statsig
                ?.getExperiment('fill_in_the_blank_placholder_text')
                .get('text', state.fillInTheBlankPlaceholderText),
        })
    },
    loadStickyHeaderTests({ commit }) {
        commit('SET_VALUE', {
            stickyHeader: window.statsig?.getExperiment('zoom_container_sticky').get('sticky', false),
        })
    },
}

export const getters = {
    premiumMarkerColor: (state) => state.premium_marker_color,
    premiumFont: (state) => state.premium_fonts,
    defaultSelectedWidget: (state) => state.default_widget_dropdown,
    freePlanLayout: (state) => state.free_plan_layout,
    paywallType: (state) => state.paywall_type,
    headerStyles: (state) => state.header_styles,
    worksheetPrintActionText: (state) => state.worksheetPrintActionText,
    flashcardPrintActionText: (state) => state.flashcardPrintActionText,
    bingoPrintActionText: (state) => state.bingoPrintActionText,
    loadingAbAction: (state) => state.loadingMakePrintable,
    worksheetTitle: (state) => state.worksheetTitle,
    bingoTitle: (state) => state.bingoTitle,
    flashcardTitle: (state) => state.flashcardTitle,
    blankTitle: (state) => state.blank_title,
    loading: (state) => state.loading,
    showAnswerKey: (state) => state.show_answer_key,
    fillInTheBlankInstructionText: (state) => state.fillInTheBlankInstructionText,
    fillInTheBlankPlaceholderText: (state) => state.fillInTheBlankPlaceholderText,
    defaultTitle: (state) => state.defaultTitle,
    stickyHeader: (state) => state.stickyHeader,
}
